export class Complex {
  constructor(public re: number = 0, public im = 0)
    {  }

  public add = (c: Complex) => {
    this.re += c.re
    this.im += c.im
  }

  public mult = (c: Complex) => new Complex(
    this.re * c.re - this.im * c.im,
    this.re * c.im + this.im * c.re
  )

  public toString = () => `(${this.re}, ${this.im})`
}

export interface DFT extends Complex {
  freq: number,
  amp: number,
  phase: number
}

const lerp = (a: Complex, b: Complex, x: number) => {
  return new Complex(
    a.re + (b.re - a.re) * x,
    a.im + (b.im - a.im) * x
  )
}

export const remap = (n, start1, stop1, start2, stop2, withinBounds = false) => {
  const newval = (n - start1) / (stop1 - start1) * (stop2 - start2) + start2;
  if (!withinBounds) {
    return newval;
  }
}

const integrate = (fOfT: Complex[], k: number) => {
  const N = fOfT.length
  const slices = 10
  let sum = new Complex()
  for (let i = 0; i < N - 1; i++) {
    let a = remap(i, 0, N - 1, -Math.PI, Math.PI)
    let b = remap(i + 1, 0, N - 1, -Math.PI, Math.PI)
    for (let j = 0; j <= slices; j++) { // dt
      let x = remap(j, 0, slices, a, b)
      let cs = new Complex(
        Math.cos(k * x),
        Math.sin(-1 * k * x)
      )
      let midvec = lerp(fOfT[i], fOfT[i + 1], j / slices)
      sum.add(midvec.mult(cs))
    }
  }

  sum.re /= (N * slices)
  sum.im /= (N * slices)

  const freq = k;
  const amp = Math.sqrt(sum.re * sum.re + sum.im * sum.im)
  const phase = Math.atan2(sum.im, sum.re)

  return { re: sum.re, im: sum.im, freq, amp, phase }
}

const fourierTransform = (fOfT: Complex[], numberOfTerms?: number) : Map<number,DFT> => {
  const n = numberOfTerms % 2 == 0 ? numberOfTerms / 2 : (numberOfTerms + 1) / 2;

  let C = new Map()
  for (let k = -1 * n; k <= n; k++) {
    C.set(k, integrate(fOfT, k))
  }
  return C
}

export const dft = (x: Complex[], numberOfTerms?: number) : Map<number,DFT> => {
  return fourierTransform(x, numberOfTerms)
}

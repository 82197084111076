import { svgPathProperties } from "svg-path-properties";

export const parseSvg = (str: string) => {
  var doc = new DOMParser();

  let dom = doc.parseFromString(str, "image/svg+xml")

  let paths = dom.getElementsByTagName("path")

  let d = Array.from(paths)
    .map(p => p.getAttribute('d'))
    .join(' ')

  let properties = new svgPathProperties(d)

  const length = properties.getTotalLength()

  return Array.from({length: Math.ceil(length)})
    .map((_, i) => properties.getPointAtLength(i))
}

import React, { useState, useEffect, useRef } from "react";

import "./fourier.scss";
import { Complex, dft, DFT, remap } from "./complex";
import { parseSvg } from "./svg";

function epicycles(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  rotation: number,
  fourier: DFT[],
  time
) {

  for (let i = 1; i < fourier.length; i++) {
    let prevx = x;
    let prevy = y;
    let freq = fourier[i].freq;
    let radius = fourier[i].amp;
    let phase = fourier[i].phase;
    x += radius * Math.cos(freq * time + phase + rotation);
    y += radius * Math.sin(freq * time + phase + rotation);

    context.strokeStyle = "#555555";
    context.arc(prevx, prevy, radius, 0, 2 * Math.PI);
    context.stroke();

    context.beginPath();
    context.strokeStyle = "#fff";
    context.moveTo(prevx, prevy);
    context.lineTo(x, y);
    context.stroke();
  }

  return { x, y };
}

export const Fourier = (props: { src: string }) => {
  const { src } = props;
  const [numberOfCircles, setNumberOfCircles] = useState(200);
  const [coords, setCoords] = useState([]);
  const [width, setWidth] = useState(300);

  const ref = useRef();

  useEffect(() => {
    fetch(src)
      .then((r) => r.text())
      .then(parseSvg)
      .then((points) => {
        if (ref.current) {
          setCoords(points.map((p) => new Complex(p.x * 6, p.y * 6)));
        }
      });
  }, [src]);

  useEffect(() => {
    let timer;
    let requestId,
      i = 0;
    let canvas = ref.current || null;
    if (canvas && coords.length) {
      let context = canvas.getContext("2d");

      let ratio = 1;
      let height = width * ratio;
      canvas.height = height;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${width}px`;

      const ft = [...dft(coords, numberOfCircles).values()].sort(
        (a, b) => b.amp - a.amp
      );

      let time = 0;
      const dt = (0.5 * (2 * Math.PI)) / ft.length;
      let path = [];
      const draw = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        const point = epicycles(context, width / 2, height / 2, 0, ft, time);
        path.unshift(point);
        context.beginPath();
        context.strokeStyle = "#ffff00";
        for (let i = 1; i < path.length; i++) {
          context.moveTo(path[i - 1].x, path[i - 1].y);
          context.lineTo(path[i].x, path[i].y);
        }
        context.stroke();

        time += dt;
        if (time > 2 * Math.PI) {
          time = 0;
          path = [];
        }

        timer = requestAnimationFrame(draw);
      };

      draw();
    }

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [numberOfCircles, coords, width]);

  return (
    <div className="fourier">
      <img src={src} />
      <div style={{ width: "100%" }}>
        <div className="controls">
          <label>
            Epicycles: {`${numberOfCircles}`.padStart(2, '0')}
            <input
              type="range"
              min="50" max={300} step={1}
              value={numberOfCircles}
              onChange={e => setNumberOfCircles(e.target.valueAsNumber)} />
          </label>
        </div>
        <canvas ref={ref} />
      </div>
    </div>
  );
};
